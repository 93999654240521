import React from "react"
import { Link, useStaticQuery } from "gatsby"
import GalleryCard from "../components/cards/gallerycard"

import Layout from "../components/layout"

const Color = () => {
  const data = useStaticQuery(
    graphql`
      {
        allPrismicGallery(filter: { uid: { eq: "color" } }) {
          edges {
            node {
              data {
                body {
                  ... on PrismicGalleryBodyImageGallery {
                    id
                    items {
                      gallery_image {
                        fluid(maxWidth: 1000, maxHeight: 800) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      size {
                        text
                      }
                      medium
                      price
                      year
                      image_name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  //console.log(data.allPrismicTestpage.edges[0].node.data.body[0].items)

  return (
    <Layout>
      <div className="max-w-2xl mx-auto">
        <p className="px-4">
          Between the icons of our mind there is a place, where we can catch
          some kind of strange unknown feelings. It's like flashes, or
          frequency! It feels like stream of constant movement! Always abstract.
          I try to follow it , Like hypnotized zombie! I think it's like
          meditation. try to catch some of them when I am painting, and they
          always come before I sleep!
        </p>
        {data.allPrismicGallery.edges[0].node.data.body[0].items.map(
          (item, i) => (
            <GalleryCard image={item} key={i} />
          )
        )}
      </div>
    </Layout>
  )
}

export default Color
